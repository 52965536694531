<template>
  <div id="app" ref="appRef">
    <router-view></router-view>
  </div>
</template>

<script>
import drawMixin from "./mixin/index.js";
export default {
  mixins: [drawMixin],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#app {
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  background: #131836;
}
</style>
